import React from 'react';
import type { Placement1Axis, Size } from '../../types/component.type';
import './row.component.scss';

class Static {
  static interpretAlign: Record<string, string> = Object.freeze({
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
    spaceBetween: 'space-between',
  });
}

export declare namespace RowType {
  type Props = {
    className?: string;
    children: React.ReactNode;
    config?: {
      wrap?: boolean;
      vertical?: Extract<Placement1Axis, 'start' | 'center' | 'end'>;
      horizontal?: Extract<
        Placement1Axis,
        'start' | 'center' | 'spaceBetween' | 'end'
      >;
      width?: Extract<Size, 'initial' | 'full'>;
      height?: Extract<Size, 'initial' | 'full'>;
      gap?: number;
    };
  };
}

export const Row = ({
  children,
  className = '',
  config: {
    wrap = false,
    vertical = 'start',
    horizontal = 'start',
    width = 'full',
    height = 'initial',
    gap = 0,
  } = {},
}: RowType.Props) => {
  return (
    <div
      style={{
        flexWrap: wrap ? 'wrap' : 'nowrap',
        justifyContent: Static.interpretAlign[horizontal],
        alignItems: Static.interpretAlign[vertical],
        gap: `${gap}px`,
      }}
      className={`row row--width--${width} row--height--${height} ${className}`}
    >
      {children}
    </div>
  );
};
