import { DatePicker as DatePickerAnt } from 'antd';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { useTranslation } from '../../hook/useTranslation.hook';
import type { Size } from '../../types/component.type';
import { Form, FormItemType } from '../form/form.component';
import { Write } from '../write/write.component';
import './datepicker.component.scss';
class Static {
  static formatReference = Object.freeze({
    datetime: 'DD-MM-YYYY HH:mm:ss',
    date: 'DD-MM-YYYY',
  } as const);

  static dateJSToISOString = (
    date: dayjs.Dayjs | DatePickerType.Helper.Range<dayjs.Dayjs>,
  ): DatePickerType.Helper.Range<Date> | Date => {
    return Array.isArray(date)
      ? (date.map(
          (value) => new Date(dayjs(value).toISOString()),
        ) as DatePickerType.Helper.Range<Date>)
      : new Date(dayjs(date).toISOString());
  };

  static dateToDateJS = (
    date: Date | DatePickerType.Helper.Range<Date>,
  ): DatePickerType.Helper.Range<dayjs.Dayjs> | dayjs.Dayjs => {
    return Array.isArray(date)
      ? (date.map((value) =>
          dayjs(value),
        ) as DatePickerType.Helper.Range<dayjs.Dayjs>)
      : dayjs(date);
  };

  static formatConverter = (
    date: DatePickerType.Config.Format,
  ): (typeof Static.formatReference)[keyof typeof Static.formatReference] =>
    Static.formatReference?.[date];
}

export declare namespace DatePickerType {
  type Props = {
    className?: string;
    handleEvent?: {
      date?: (
        value: string | DatePickerType.Helper.Range<string> | undefined,
      ) => void;
    };
    data?: {
      defaultValue?: Date | DatePickerType.Helper.Range<Date>;
      value?: Date | DatePickerType.Helper.Range<Date>;
    };
    config?: {
      mode?: 'single' | 'range';
      presets?: Array<
        DatePickerType.Config.Preset<
          dayjs.Dayjs | DatePickerType.Helper.Range<dayjs.Dayjs>
        >
      >;
      width?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;
      height?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;
      disabled?: boolean;
      format?: DatePickerType.Config.Format;
      defaultTime?: 'startDay' | 'endDay';
      clear?: boolean;
      min?: DatePickerType.Config.Min;
      max?: DatePickerType.Config.Max;
    };
  };

  namespace Config {
    type Preset<T> = { label: string; value: T };
    type Format = 'date' | 'datetime';
    type Min = string | null;
    type Max = string | null;
  }

  namespace Helper {
    type Range<T> = [T, T];
  }
}

export const DatePicker = ({
  data: { defaultValue, value } = {},
  handleEvent: { date } = {},
  config: {
    mode = 'single',
    width,
    height,
    disabled = false,
    presets = [],
    format = 'date',
    clear = false,
    min,
    max,
    defaultTime,
  } = {},
  data,
  className = '',
  value: valueFormItem,
  onChange: onChangeFormItem,
  ...formItem
}: FormItemType.Legacy<DatePickerType.Props>) => {
  const { t } = useTranslation();

  const onChangeData = useCallback(
    (
      data:
        | (dayjs.Dayjs | null)
        | DatePickerType.Helper.Range<dayjs.Dayjs | null>,
    ) => {
      if (!data) {
        date?.(undefined);
        onChangeFormItem?.(undefined);
      }

      if (
        !(
          !data ||
          (Array.isArray(data) && data.filter(Boolean).length !== data.length)
        )
      ) {
        const renderData = Static.dateJSToISOString(
          format === 'date'
            ? Array.isArray(data)
              ? ([
                  dayjs(data[0]).startOf('day'),
                  dayjs(data[1]).endOf('day'),
                ] as DatePickerType.Helper.Range<dayjs.Dayjs>)
              : (dayjs(data).startOf('day') as dayjs.Dayjs)
            : (data as dayjs.Dayjs | DatePickerType.Helper.Range<dayjs.Dayjs>),
        );

        const renderDataString = Array.isArray(renderData)
          ? (renderData.map((date) =>
              dayjs(date).toISOString(),
            ) as DatePickerType.Helper.Range<string>)
          : dayjs(renderData).toISOString();

        date?.(renderDataString);
        onChangeFormItem?.(renderDataString);
      }
    },
    [defaultTime],
  );

  const presetFormater = useCallback(
    (
      data: Array<
        DatePickerType.Config.Preset<
          dayjs.Dayjs | DatePickerType.Helper.Range<dayjs.Dayjs>
        >
      >,
    ) =>
      data.map(({ label, value }) => ({
        label: (
          <Write
            data={{
              item: label,
            }}
            config={{
              mode: 'value-small',
            }}
          ></Write>
        ),
        value,
      })),
    [],
  );

  const disableTime = useCallback(
    (currentDay: dayjs.Dayjs) => {
      const currentLuxDate = DateTime.fromJSDate(currentDay.toDate());
      const minLuxDate = min && DateTime.fromISO(min);
      const maxLuxDate = max && DateTime.fromISO(max);
      const disabledHours: Array<number> = [];
      const disabledMinutes: Array<number> = [];
      const disabledSeconds: Array<number> = [];

      if (minLuxDate) {
        const isSameDay = currentLuxDate.hasSame(minLuxDate, 'day');
        const isSameHour = currentLuxDate.hasSame(minLuxDate, 'hour');
        const isSameMinute = currentLuxDate.hasSame(minLuxDate, 'minute');

        if (isSameDay)
          for (let i = minLuxDate.hour - 1; i >= 0; i--) disabledHours.push(i);
        if (isSameHour)
          for (let i = minLuxDate.minute - 1; i >= 0; i--)
            disabledMinutes.push(i);
        if (isSameMinute)
          for (let i = minLuxDate.second - 1; i >= 0; i--)
            disabledSeconds.push(i);
      }

      if (maxLuxDate) {
        const isSameDay = currentLuxDate.hasSame(maxLuxDate, 'day');
        const isSameHour = currentLuxDate.hasSame(maxLuxDate, 'hour');
        const isSameMinute = currentLuxDate.hasSame(maxLuxDate, 'minute');

        if (isSameDay)
          for (let i = maxLuxDate.hour + 1; i <= 24; i++) disabledHours.push(i);
        if (isSameHour)
          for (let i = maxLuxDate.minute + 1; i <= 59; i++)
            disabledMinutes.push(i);
        if (isSameMinute)
          for (let i = maxLuxDate.second + 1; i <= 59; i++)
            disabledSeconds.push(i);
      }

      return {
        disabledHours: () => disabledHours,
        disabledMinutes: () => disabledMinutes,
        disabledSeconds: () => disabledSeconds,
      };
    },
    [min, max],
  );

  return (
    <Form.Context.Consumer>
      {(form) => (
        <>
          {mode === 'single' ? (
            <DatePickerAnt
              minDate={min ? dayjs(min) : undefined}
              maxDate={max ? dayjs(max) : undefined}
              className={`
                formItemTargetWidth--${form.width || width || 'medium'}
                datepicker 
                datepicker--width--${mode}--${form.width || width || 'medium'}
                datepicker--height--${form.height || height || 'medium'}
                ${className}
              `}
              disabledTime={disableTime}
              showTime={
                format === 'datetime'
                  ? {
                      defaultOpenValue:
                        defaultTime === 'startDay'
                          ? dayjs('00:00:00', 'HH:mm:ss')
                          : defaultTime === 'endDay'
                            ? dayjs('23:59:59', 'HH:mm:ss')
                            : undefined,
                    }
                  : false
              }
              onChange={(date: dayjs.Dayjs | null) => onChangeData(date)}
              defaultValue={
                defaultValue
                  ? (Static.dateToDateJS(defaultValue) as dayjs.Dayjs)
                  : undefined
              }
              value={
                ((value || valueFormItem) as Date | undefined)
                  ? (Static.dateToDateJS(value || valueFormItem) as dayjs.Dayjs)
                  : undefined
              }
              disabled={disabled}
              format={Static.formatConverter(format)}
              allowClear={clear}
              presets={
                presets && presets.length > 0
                  ? presetFormater(
                      presets as DatePickerType.Config.Preset<dayjs.Dayjs>[],
                    )
                  : undefined
              }
              {...formItem}
            />
          ) : mode === 'range' ? (
            <DatePickerAnt.RangePicker
              minDate={min ? dayjs(min) : undefined}
              maxDate={max ? dayjs(max) : undefined}
              className={`
                formItemTargetWidth--${form.width || width || 'medium'}
                datepicker 
                datepicker--width--${mode}--${form.width || width || 'medium'}
                datepicker--height--${form.height || height || 'medium'}
                ${className}
              `}
              placeholder={[t('watermelon-startDate'), t('watermelon-endDate')]}
              showTime={
                format === 'datetime'
                  ? {
                      hideDisabledOptions: true,
                      defaultValue: [
                        dayjs('00:00:00', 'HH:mm:ss'),
                        dayjs('23:59:59', 'HH:mm:ss'),
                      ],
                    }
                  : false
              }
              onChange={(
                date: DatePickerType.Helper.Range<dayjs.Dayjs | null>,
              ) => onChangeData(date)}
              defaultValue={
                (defaultValue as DatePickerType.Helper.Range<Date> | undefined)
                  ? (Static.dateToDateJS(
                      defaultValue as DatePickerType.Helper.Range<Date>,
                    ) as DatePickerType.Helper.Range<dayjs.Dayjs> | undefined)
                  : undefined
              }
              value={
                ((value || valueFormItem) as
                  | DatePickerType.Helper.Range<Date>
                  | undefined)
                  ? (Static.dateToDateJS(value || valueFormItem) as
                      | DatePickerType.Helper.Range<dayjs.Dayjs>
                      | undefined)
                  : undefined
              }
              disabled={disabled}
              format={Static.formatConverter(format)}
              allowClear={clear}
              presets={
                presets && presets.length > 0
                  ? presetFormater(
                      presets as DatePickerType.Config.Preset<
                        DatePickerType.Helper.Range<dayjs.Dayjs>
                      >[],
                    )
                  : undefined
              }
              {...formItem}
            />
          ) : null}
        </>
      )}
    </Form.Context.Consumer>
  );
};
