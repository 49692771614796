export default () => (
  <svg
    width="50"
    height="90"
    viewBox="0 0 50 90"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 4.5C15.5 3.67157 16.1716 3 17 3H34C34.8284 3 35.5 3.67157 35.5 4.5V85.5C35.5 86.3284 34.8284 87 34 87H17C16.1716 87 15.5 86.3284 15.5 85.5V4.5ZM18.5 6V84H32.5V6H18.5Z"
      style={{
        fill: 'currentcolor',
      }}
    />
    <path
      d="M6.5 85.5C6.5 84.6716 7.17157 84 8 84H42C42.8284 84 43.5 84.6716 43.5 85.5C43.5 86.3284 42.8284 87 42 87H8C7.17157 87 6.5 86.3284 6.5 85.5Z"
      style={{
        fill: 'currentcolor',
      }}
    />
    <path
      d="M15.5 45.5C15.5 44.6716 16.1716 44 17 44H34C34.8284 44 35.5 44.6716 35.5 45.5C35.5 46.3284 34.8284 47 34 47H17C16.1716 47 15.5 46.3284 15.5 45.5Z"
      style={{
        fill: 'currentcolor',
      }}
    />
    <path
      d="M15.5 21.5C15.5 20.6716 16.1716 20 17 20H34C34.8284 20 35.5 20.6716 35.5 21.5C35.5 22.3284 34.8284 23 34 23H17C16.1716 23 15.5 22.3284 15.5 21.5Z"
      style={{
        fill: 'currentcolor',
      }}
    />
    <path
      d="M21.5 39.5C21.5 38.6716 22.1716 38 23 38H28C28.8284 38 29.5 38.6716 29.5 39.5C29.5 40.3284 28.8284 41 28 41H23C22.1716 41 21.5 40.3284 21.5 39.5Z"
      style={{
        fill: 'currentcolor',
      }}
    />
  </svg>
);
