import { AuthStoreMobX } from './auth.store';
import { GlobalStoreMobX } from './global.store';
import { SSOStoreMobX } from './sso.store';
import { ThemeStoreMobX } from './theme.store';

export class RootStoreMobX {
  ThemeStore: ThemeStoreMobX;
  GlobalStore: GlobalStoreMobX;
  AuthStore: AuthStoreMobX;
  SSOStore: SSOStoreMobX;

  constructor() {
    this.GlobalStore = new GlobalStoreMobX(this);
    this.ThemeStore = new ThemeStoreMobX(this);
    this.AuthStore = new AuthStoreMobX(this);
    this.SSOStore = new SSOStoreMobX(this);
  }
}
