import { Button as ButtonAnt } from 'antd';
import ColorFn from 'color';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { getCSS } from '../../functions/css.function';
import { useRootStore } from '../../hook/useRootStore.hook';
import type {
  Color as ColorGlobal,
  Size,
  Weight,
} from '../../types/component.type';
import { Icon, IconType } from '../icon/icon.component';
import './button.component.scss';

export declare namespace ButtonType {
  type Props = {
    className?: string;
    handleEvent?: {
      click?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    };
    config?: {
      text?: string | number | React.ReactNode;
      type?: ButtonType.Config.Type;
      size?: Extract<Size, 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'>;
      weight?: Extract<Weight, 'bold' | 'normal' | 'light'>;
      color?: ButtonType.Config.Color;
      mode?: 'fill' | 'stroke' | 'dash' | 'link';
      icon?: IconType.Config.Type;
      disabled?: boolean;
      stopPropagation?: boolean;
      testId?: string;
    };
  };

  namespace Config {
    type Type =
      | { value: 'button' }
      | { value: 'submit' }
      | {
          value: 'upload';
          upload: (event: FileList) => void;
          multiple?: boolean;
          accept?: string;
        };

    type Color = Extract<
      ColorGlobal,
      | 'yin'
      | 'yang'
      | 'white'
      | 'black'
      | 'success'
      | 'warn'
      | 'error'
      | 'primary'
    >;
  }
}

export const Button = observer(
  ({
    className = '',
    handleEvent: { click } = {},
    config: {
      text,
      type,
      size = 'large',
      disabled = false,
      color = 'primary',
      mode = 'fill',
      weight = 'bold',
      icon,
      stopPropagation = false,
      testId,
    } = {},
  }: ButtonType.Props) => {
    const { ThemeStore } = useRootStore();

    const typeFormatted = type || { value: 'button' };

    const currentColor: 'black' | 'white' | undefined = useMemo(() => {
      return mode === 'fill'
        ? new ColorFn(getCSS(`--color-${color}`)).isLight()
          ? 'black'
          : 'white'
        : undefined;
    }, [ThemeStore.theme]);

    return (
      <div
        className={`
        button
        button--mode--${mode}
        ${className}
      `}
      >
        <ButtonAnt
          style={{ color: currentColor }}
          data-testid={testId}
          className={`
          button__item 
          button__item--color--${disabled ? 'disabled' : color}
          button__item--mode--${mode}
          button__item--weight--${weight}
          button__item--size--${size}
        ${className}
      `}
          onClick={(event) => {
            if (stopPropagation) event.stopPropagation();
            click?.(event);
          }}
          htmlType={
            typeFormatted.value === 'upload' ? 'button' : typeFormatted.value
          }
          disabled={!!disabled}
          ghost={true}
        >
          {typeFormatted.value === 'upload' && (
            <div className="button__item__upload">
              <label className="button__item__upload__label">
                <input
                  value={''} //! Permet de charger le même fichier plusieurs fois d'affilé
                  onChange={(e) => {
                    const filesList = e?.target?.files;
                    if (filesList) typeFormatted.upload(filesList);
                  }}
                  type="file"
                  accept={typeFormatted?.accept}
                  multiple={typeFormatted?.multiple || false}
                  className="button__item__upload__label__input"
                ></input>
              </label>
            </div>
          )}
          <div className="button__item__inner">
            <div className="button__item__inner__text">{text}</div>
            {text && icon && (
              <div className="button__item__inner__separa"></div>
            )}
            {icon && (
              <Icon
                className="button__item__inner__icon"
                config={{
                  type: icon,
                  color: currentColor,
                }}
              ></Icon>
            )}
          </div>
        </ButtonAnt>
      </div>
    );
  },
);
