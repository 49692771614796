import React from 'react';

export default () => (
  <svg
    style={{
      verticalAlign: 'middle',
      fill: 'currentColor',
      overflow: 'hidden',
    }}
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M907 268.4c-162.2 0-242.8-61.2-301.7-105.9-34.2-26-61.2-46.5-92.8-46.5-30.4 0-53.5 19.4-82.7 43.9-54.6 45.9-129.3 108.6-311.9 108.6-3.9 0-5.3 0.8-5.5 1.2-0.3 0.5-1.3 3.7 5.5 15.7 5.1 9 13.1 20.3 21.5 32.3 20.1 28.5 72.6 120 76 146.3 0 0 74-91.3 301.5-89.5 195.9-2.5 270.5 83.3 280.5 86.2 3.6-29.3 50-121.9 79.4-151.2 10-9.9 19.4-19.3 25.9-26.9 7.5-8.7 8.9-12.4 9.1-13.6-0.7-0.3-2.1-0.6-4.8-0.6z m-353.8 69.2c-19.2 11.9-38.1 19.7-38.9 20l-2 0.8-2-0.8c-0.8-0.3-19.7-8.1-38.9-20-26.9-16.6-40.6-32.6-40.6-47.6v-68.1h162.5l0.5 68.1c0 14.9-13.6 31-40.6 47.6z" />
    <path d="M811 528.3l-3.6-1.2v-8.3c0-6.8-3.1-9.9-7.4-14.2l-1.5-1.5v-2.2c0-4.5-1.4-9.2-2.6-13.4-1-3.2-1.7-5.9-1.9-8.3h-12l9-9c2-2 4.4-4.4 5.9-6.5-21.8-7-116.1-33.8-289.1-33.8s-267.3 26.8-289.1 33.8c1.5 2.1 3.9 4.5 5.9 6.5l1.5 1.5v7.9l-0.6 1.1c-3.9 7.8-3.9 15.9-3.9 24.5v2.2l-1.5 1.6c-4.3 4.3-7.4 7.4-7.4 14.2v8.3l-3.6 1.2c-38.6 12.9-63.6 51.4-63.6 98.1 0 29.3 18.3 52.8 33.7 67.3 19.4 18.4 45 32.8 70.1 39.7l2.6 0.7 0.9 2.5c24.1 65 79.9 105.4 122.5 127.8 51.1 26.9 104.5 39.2 132.6 39.2 28 0 81.5-12.3 132.6-39.2C683.1 846.4 739 806 763 741.1l0.9-2.5 2.6-0.7c24-6.6 50.2-22 70-41.3 21.7-21.1 33.7-44.5 33.7-65.7v-0.4c4-48.8-19.2-88.9-59.2-102.2z m-67.5-5.8S631 581 512 581s-231.5-58.5-231.5-58.5V489s137-19.5 231.5-19.5S743.5 489 743.5 489v33.5z" />
  </svg>
);
