import Keycloak, { KeycloakProfile } from 'keycloak-js';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { requestGQLWithoutSchema } from '../functions/request.function';
import { RootStoreMobX } from './root.store';

export type SSOContextType = {
  mode: { type: 'parent' } | { type: 'children'; parentUrl: string } | null;
  instance: Keycloak | null;
  account?: KeycloakProfile | null;
  user?: unknown;
  resources?: {
    modules: Array<{
      name: string;
      label: string;
      description: string;
      color: string;
      title: string;
      url: string;
      canUse: boolean;
    }>;
    parentUrl: string;
  };
};

export class SSOStoreMobX {
  root: RootStoreMobX;
  @observable mode: SSOContextType['mode'] = null;
  @observable instance: SSOContextType['instance'] = null;
  @observable user: SSOContextType['user'] = null;
  @observable load: boolean = false;
  @observable account: KeycloakProfile | null = null;
  @observable roles: Array<string> = [];
  @observable authenticated: boolean = false;
  @observable resources: SSOContextType['resources'];

  constructor(root: RootStoreMobX) {
    this.root = root;
    makeAutoObservable(this);
  }

  @action public async auth() {
    if (this.instance && !this.instance?.authenticated) {
      const checkSession = await this.checkExistingSession();

      if (!checkSession) {
        await this.instance.login();
      }

      if (this.instance?.authenticated && this.instance.token) {
        this.registerAccount(this.instance);
        this.registerRoles(this.instance);
        this.registerAuthenticated(this.instance);

        sessionStorage.setItem('token', this.instance.token);
      }

      setInterval(async () => {
        try {
          const refreshed = await this.instance?.updateToken(50);

          if (this.instance?.token) {
            sessionStorage.setItem('token', this.instance.token);
          }

          if (refreshed) {
            console.log('Token rafraîchi avec succès');
          } else {
            console.log('Token toujours valide');
          }
        } catch (error) {
          console.error('Erreur lors du rafraîchissement du token:', error);
        }
      }, 30000);

      try {
        const { list, paging } = await requestGQLWithoutSchema({
          //! TEMP
          operationName: 'resources',
          operationType: 'QUERY',
          params: {
            limit: 300,
          },
        });

        this.resources = {
          modules: list,
          parentUrl:
            this.mode?.type === 'children'
              ? this.mode.parentUrl
              : location.origin,
        };

        if (this.mode?.type === 'children') {
          const me = await requestGQLWithoutSchema({
            operationName: 'me',
            operationType: 'QUERY',
          });

          this.user = me;
        }
      } catch (e) {
        console.log({ e });
        this.user = null;
      }
    }
  }

  @action public async checkExistingSession(): Promise<boolean> {
    try {
      const checkSSO = await this.instance?.init({
        onLoad: 'check-sso',
      });

      return !!checkSSO;
    } catch {
      return false;
    }
  }

  @action public logout() {
    this.instance?.logout();
  }

  @observable private registerRoles(instance: SSOContextType['instance']) {
    this.roles =
      instance?.resourceAccess &&
      instance?.clientId &&
      instance?.clientId in instance.resourceAccess
        ? Object.values(instance.resourceAccess?.[instance?.clientId]?.roles)
        : [];
  }

  @observable public registerAuthenticated(
    instance: SSOContextType['instance'],
  ) {
    this.authenticated = !!instance?.authenticated;
  }

  @action private async registerAccount(instance: SSOContextType['instance']) {
    this.account = (await instance?.loadUserProfile()) || null;
  }

  @action public async init({
    mode,
    instance,
  }: Pick<SSOContextType, 'mode' | 'instance'>) {
    sessionStorage.setItem('auth-mode', 'sso');

    runInAction(() => {
      this.load = false;
      this.mode = mode;
      this.instance = instance;
      this.auth();
      this.load = true;
    });
  }
}
