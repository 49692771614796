import { dispatch, useLocation, useNavigate } from '@gimlite/router';
import { useRootStore } from '@gimlite/watermelon';
import IEM from '@gimlite/watermelon/assets/logo/IEM_small';
import { Main } from '@gimlite/watermelon/components/main/main.component';
import {
  endOfDay,
  yesterday,
} from '@gimlite/watermelon/functions/date.function';
import { toCapitalizeCase } from '@gimlite/watermelon/functions/string.function';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import LogoIEMPrestoScan from './assets/logo/iem-prestoscan';
import { truncateString } from './common/fun/string';
import { UPS } from './common/types/ups';

const MainUse = observer(() => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { buildParamsUrl } = useMyUrl();
  const { t, lang }: any = useTranslation();
  const { AuthStore } = useRootStore();

  const me = AuthStore.me as any;

  const eventsDefaultParams = {
    startDate: yesterday('DATE'),
    endDate: endOfDay('DATE'),
    cameraId: 'all',
    way: 'all',
    knownLpn: 'yes',
  };

  const vaoDefaultParams = {
    minUncoveredDuration:
      localStorage.getItem('minUncoveredDuration') || undefined,
  };

  // Get account informations and dictionnary
  useEffect(() => {
    dispatch('I18N', {
      operationName: 'i18n',
      operationType: 'QUERY',
      params: { key: 'bff-prestoscan-global' },
    });

    AuthStore.initMe();
  }, []);

  useEffect(() => {
    if (me) {
      const { firstName, lastName, ups, lang } = me;

      AuthStore.setNames({ firstName, lastName });

      // Select user language
      dispatch('SELECTED_LANG', { lang });

      // Handle redirection at first loading
      if (pathname === '/')
        navigate(
          `/${ups[0]?._id}/events?${buildParamsUrl(eventsDefaultParams)}`,
        );
    }
  }, [me]);

  useEffect(() => {
    if (me) {
      const { ups, firstName, lastName } = me;

      const multipleCities =
        [
          ...new Set(
            ups.map((ups: UPS) => ups.address.postalCode).filter(Boolean),
          ),
        ].length > 1;

      dispatch('SET_UPS_CONFIG', {
        payload: ups.reduce(
          (acc: Record<string, unknown>, { _id, ...rest }: any) => ({
            ...acc,
            [_id]: { ...rest },
          }),
          {},
        ),
      });

      dispatch('REGISTER_MENU', {
        menu: [
          {
            key: 'top',
            position: 'top',
            title: t('allParkingLots'),
            items: ups.map(({ _id, code, address }: UPS) => {
              const items: any[] = [
                {
                  key: `/${_id}/events`,
                  params: eventsDefaultParams,
                  label: toCapitalizeCase(t('entries/exits')),
                  icon: 'faCarSolid',
                },
                {
                  key: `/${_id}/vao`,
                  params: vaoDefaultParams,
                  label: `${t('vao')}`.toUpperCase(),
                  icon: 'faCameraSolid',
                },
              ];

              // if (config?.prestoScan?.canShowOccupancy)items.push({
              //   key: `/${_id}/occupancy`,
              //   label: `${t('occupancy')}`.toUpperCase(),
              //   icon: <PieChartFilled style={{ fontSize: '24px' }} />,
              // });

              return {
                key: `/${_id}/events`,
                label: truncateString(
                  multipleCities && address.addressLocality
                    ? `${address.addressLocality} - ${t(`ups-${_id}-name`)}`
                    : t(`ups-${_id}-name`),
                  27,
                ),
                icon: 'faSquareParkingSolid',
                items,
              };
            }),
          },
        ],
      });
    }
  }, [me, lang, vaoDefaultParams]);

  return (
    <Main
      handleEvent={{
        selected: (key: string) => {
          if (key === 'logout') {
            dispatch('LOGOUT', {});
            navigate('/');
          } else {
            navigate(key);
          }
        },
      }}
      config={{
        navigation: {
          logo: LogoIEMPrestoScan(),
          mode: 'drop',
        },
        //! Lors d'une refact du router, ce code apparaitra une fois en global (PROBLEM 1)
        //******* START ************/
        header: {
          logo: IEM(),
        },
        //******* STOP ************/
      }}
    />
  );
});

export default MainUse;
