import React from 'react';
import type { Position } from '../../types/component.type';
import { Icon, IconType } from '../icon/icon.component';
import { Write } from '../write/write.component';
import './widget.component.scss';

declare namespace WidgetHeaderType {
  type Props = {
    config?: {
      icon?: WidgetHeaderType.Config.Icon;
      title?: WidgetHeaderType.Config.Title;
      extra?: WidgetHeaderType.Config.Extra;
      subtitle?: WidgetHeaderType.Config.SubTitle;
      backtitle?: WidgetHeaderType.Config.BackTitle;
      actions?: WidgetHeaderType.Config.Action;
    };
  };
  namespace Config {
    type Icon = IconType.Config.Type;
    type Title = string;
    type SubTitle = string;
    type BackTitle = boolean;
    type Extra = React.ReactNode;
    type Action = React.ReactNode[];
  }
}

const WidgetHeader = ({
  config: { icon, title, extra, subtitle, actions, backtitle } = {},
}: WidgetHeaderType.Props) => {
  return (
    <div className={`wid-header ${backtitle ? 'wid-header-background' : ''}`}>
      {icon && (
        <Icon
          className="wid-header__icon"
          config={{
            type: icon,
            size: 'xmedium',
            color: backtitle ? 'primary-over' : undefined,
          }}
        ></Icon>
      )}
      <div className="wid-header__info">
        {title && (
          <div className="wid-header__info__text">
            <Write
              className="wid-header__info__text__title"
              data={{ item: title }}
              config={{
                mode: 'title-small',
                color: backtitle ? 'primary-over' : undefined,
              }}
            />
            {subtitle && (
              <div className={`wid-header__info__text__subtitle`}>
                <Write
                  data={{ item: subtitle }}
                  config={{
                    mode: 'title-small-description',
                    color: backtitle ? 'primary-over' : undefined,
                  }}
                />
              </div>
            )}
          </div>
        )}
        <div className="wid-header__info__action">
          {actions?.map((element) => (
            <div className="wid-header__info__action__item">{element}</div>
          ))}
        </div>
      </div>
      {extra && <div className="wid-header__extra">{extra}</div>}
    </div>
  );
};

export declare namespace WidgetType {
  type Props = {
    className?: string;
    children: React.ReactNode;
    config?: {
      shadow?: boolean;
      position?: Extract<
        Position,
        'sticky' | 'absolute' | 'relative' | 'static'
      >;
      full?: boolean;
      action?: WidgetType.Config.Action;
      yMax?: string | number;
      responsive?: {
        padding?: boolean;
      };
      padding?: boolean;
    } & WidgetHeaderType.Props['config'];
  };

  namespace Config {
    type Action = React.ReactNode[];
  }
}

export const Widget = ({
  children,
  config: {
    shadow = true,
    title,
    subtitle,
    extra,
    position,
    full,
    icon,
    action,
    padding = true,
    backtitle = false,
    yMax,
    responsive: { padding: responsivePadding = true } = {},
  } = {},
  className = '',
}: WidgetType.Props) => {
  const activeHeader = icon || extra || subtitle || title;

  return (
    <div
      className={`
        widget
        ${className}
      `}
      style={{
        gridTemplateRows: activeHeader ? 'min-content 1fr' : '1fr',
        position,
        top: position ? '0px' : 'initial',
        zIndex: position ? '1000' : 'initial',
        height: yMax,
        padding: full ? '0px' : undefined,
        boxShadow: !shadow ? 'none' : undefined,
      }}
    >
      {activeHeader && (
        <WidgetHeader
          config={{
            icon,
            extra,
            subtitle,
            title,
            actions: action
              ? Array.isArray(action)
                ? action
                : [action]
              : undefined,
            backtitle,
          }}
        ></WidgetHeader>
      )}
      <div
        className={`widget__contain 
        ${!responsivePadding ? 'widget-responsive-nopadding' : ''}
        ${padding ? 'widget-padding' : ''}
        ${!activeHeader ? 'widget-without-title' : ''}`}
      >
        {children}
      </div>
    </div>
  );
};

export declare namespace WidgetGroupType {
  type Props = {
    className?: string;
    children: React.ReactNode;
    config?: {
      padding?: boolean;
      responsive?: {
        padding?: boolean;
      };
    } & WidgetHeaderType.Props['config'];
  };
}

Widget.Group = ({
  className = '',
  config: {
    title,
    extra,
    icon,
    subtitle,
    backtitle = false,
    padding = true,
    responsive: { padding: responsivePadding = true } = {},
  } = {},
  children,
}: WidgetGroupType.Props) => {
  const activeHeader = icon || extra || subtitle || title;

  return (
    <div
      className={`
        widgetgroup
        ${className}
      `}
      style={{
        gridTemplateRows: activeHeader ? 'min-content 1fr' : '1fr',
      }}
    >
      {activeHeader && (
        <WidgetHeader
          config={{
            icon,
            extra,
            subtitle,
            title,
            backtitle,
          }}
        ></WidgetHeader>
      )}
      <div
        className={`widgetgroup__contain 
        ${!responsivePadding ? 'widget-responsive-nopadding' : ''}
        ${padding ? 'widget-padding' : ''}
        ${!activeHeader ? 'widget-without-title' : ''}`}
      >
        {children}
      </div>
    </div>
  );
};

export const WidgetGroup = ({
  className = '',
  config: {
    title,
    extra,
    icon,
    subtitle,
    backtitle = false,
    padding = true,
  } = {},
  children,
}: WidgetGroupType.Props) => {
  const activeHeader = icon || extra || subtitle || title;

  return (
    <div
      className={`
        widgetgroup
        ${className}
      `}
      style={{
        gridTemplateRows: activeHeader ? 'min-content 1fr' : '1fr',
      }}
    >
      {activeHeader && (
        <WidgetHeader
          config={{
            icon,
            extra,
            subtitle,
            title,
            backtitle,
          }}
        ></WidgetHeader>
      )}
      <div
        className={`widgetgroup__contain 
        ${padding ? 'widget-padding' : ''}
        ${!activeHeader ? 'widget-without-title' : ''}`}
      >
        {children}
      </div>
    </div>
  );
};
