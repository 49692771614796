import { assign, createMachine } from 'xstate';
import { interpret } from 'xstate';

const initialContext = {
  home: undefined,
};

const homeMachine = createMachine(
  {
    id: 'home',
    initial: 'off',
    predictableActionArguments: true,
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            actions: 'logWakeup',
            target: 'idle',
          },
        },
      },
      idle: {
        on: {
          KILL: {
            actions: 'logKill',
            target: 'off',
          },
        },
      },
    },
    on: {
      LOGOUT: {
        actions: assign(initialContext),
      },
    },
  },
  {
    actions: {
      logKill: () => {
        // console.log('home KILLED');
      },
      logWakeup: () => {
        // console.log(`home WAKEUP`);
      },
    },
    guards: {
      ready: () => true,
    },
  },
);

export const homeService = interpret(homeMachine).start();
