import dayjs from 'dayjs';
import { DateTime, Duration } from 'luxon';
import { Languages } from '../types/lang.type';

type DateModeType = 'LABEL' | 'DATE';

export const now = (mode: DateModeType): any => {
  if (mode === 'DATE') return dayjs();
  if (mode === 'LABEL') return 'now';
};

export const endOfDay = (mode: DateModeType): any => {
  if (mode === 'DATE') return dayjs().hour(23).minute(59).second(59);
  if (mode === 'LABEL') return 'endOfDay';
};

export const yesterday = (mode: DateModeType): any => {
  if (mode === 'DATE') {
    return dayjs().add(-1, 'd').hour(0).minute(0).second(0);
  }
  if (mode === 'LABEL') return 'yesterday';
};

export const lastWeek = (mode: DateModeType): any => {
  if (mode === 'DATE') return dayjs().add(-7, 'd').hour(0).minute(0).second(0);
  if (mode === 'LABEL') return 'lastWeek';
};

export const lastMonth = (mode: DateModeType): any => {
  if (mode === 'DATE')
    return dayjs().add(-1, 'month').hour(0).minute(0).second(0);
  if (mode === 'LABEL') return 'lastMonth';
};

export const baseFormattedDate = ({
  date,
  lang,
}: {
  date: string;
  lang: Languages | null;
}) => {
  const dt = DateTime.fromISO(date);
  dt.setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  return dt.toLocaleString(DateTime.DATETIME_MED, {
    locale: lang ? lang.replace('_', '-') : 'en-EN',
  });
};

export const baseFormattedDuration = ({ minutes }: { minutes: number }) => {
  const duration = Duration.fromObject({ minutes });

  return duration
    .rescale()
    .toHuman({
      unitDisplay: 'short',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
    .split(',')
    .reduce((acc, value) => {
      if (value.includes('ms')) return acc;
      return `${acc} ${value}`;
    }, '');
};
